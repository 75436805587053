import React, {useEffect, useState, useContext} from 'react';
import styles from '../styles/root.module.scss';
import { Link, Outlet } from 'react-router-dom';
import { FiSearch, FiMenu } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import logo from '../files/logo_black.png';

import { UserContext } from '../Contexts/UserContext';
import SearchModal from './SearchModal';
import MobileSideBar from './MobileSideBar';
import Modal from './Modal';
import { loginEvent } from '../Contexts/events';

export default function Root() {

  const {direction} = useContext(UserContext);
  const { t, i18n } = useTranslation();

  const [offset, setOffset] = useState(0);
  const [logoHeight, setLogoHeight] = useState('84px');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const logout = ()=>{
    sessionStorage.removeItem("tokenId");
    loginEvent.emit('loggedIn', false);
  }

  const openSearchModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsSearchModalOpen(true);
  }
  const closeSearchModal = (video)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsSearchModalOpen(false);
    if(video){
      openVideoModal(video);
    }
  }

  const openMobileMenu = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsMobileMenuOpen(true);
  }
  const closeMobileMenu = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsMobileMenuOpen(false);
  }

  const openVideoModal = (item)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsVideoModalOpen(true);
    setModalData(item);
  }
  const closeVideoModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsVideoModalOpen(false);
    setModalData({});
  }

  const onScroll = () => setOffset(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll, { passive: true });
    }
  }, []);
  useEffect(() => {
    if(offset >= 50){
      if(window.innerWidth > 642){
        document.getElementById("nav").style.padding = "8px 60px";
      }
      document.getElementById("nav").style.boxShadow = "0 4px 12px rgba(0,0,0,0.15)";
      document.getElementById("nav").style.backgroundColor = "#5E96CE";
      setLogoHeight('44px');
    }else{
      if(window.innerWidth > 642){
        document.getElementById("nav").style.padding = "8px 60px";
      }else{
        document.getElementById("nav").style.padding = "8px 12px";
      }
      document.getElementById("nav").style.boxShadow = "none";
      document.getElementById("nav").style.backgroundColor = "transparent";
      setLogoHeight('54px');
    }
  }, [offset]);

  return (
    <div style={{direction: i18n.language === 'en' ? 'ltr' : 'rtl'}} >
      <header className={styles.navWrapper} id='nav' >
        <Link to="/" className={styles.logoWrapper}  >
          <img alt="tafawaq" src={logo} style={{height: '100%', width: '100%', objectFit: "contain"}} />
        </Link>
        <ul>
          <li>
            <Link to="/" className={styles.nav_btn} >
              <span>{t("navBar.home")}</span>
            </Link>
          </li>
          {/* <li>
            <Link className={styles.nav_btn} >
              <span>{t("navBar.about")}</span>
            </Link>
          </li> */}
          <li>
            <Link className={styles.nav_btn} to="/categories" >
              <span>{t("navBar.more")}</span>
            </Link>
          </li>
          <li>
            <Link onClick={()=> logout() } className={styles.nav_btn} to="/" >
              <span>{t("navBar.logout")}</span>
            </Link>
          </li>
          <li>
            <div className={styles.nav_btn} style={{cursor: 'pointer'}} onClick={()=> openSearchModal() } >
              <FiSearch size={18} strokeWidth="1" style={{marginTop: 3}} />
            </div>
          </li>
        </ul>
        <FiMenu size={22} strokeWidth="1" className={styles.phoneMenu} style={{marginTop: 3}} onClick={()=> openMobileMenu() } />
      </header>
      <Outlet />

      <SearchModal isOpen={isSearchModalOpen} close={closeSearchModal} />
      { isMobileMenuOpen && <MobileSideBar close={closeMobileMenu} />}
      <Modal isOpen={isVideoModalOpen} close={closeVideoModal} title={ i18n.language === "en" ? modalData.en_title : modalData.ar_title} >
        <div style={{
          width: '100%',
          height: window.innerWidth < 642 ? window.innerHeight*0.3 : window.innerHeight*0.5
        }} >
          <iframe
            src={`https://player.vimeo.com/video/${modalData.id}?byline=0&portrait=0&title=0&fullscreen=1`}
            title={modalData.en_title}
            style={{ width: '100%', height: '100%', border: 'none' }}
            frameborder="0"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
            >
          </iframe>
        </div>
      </Modal>
    </div>
  )
}
